import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`One must not ask whether they have encountered a Shifter before, but when. They live among us. They could be your best friend, your neighbor, your cousin. They are completely imperceptible. When they shift, they do it completely and wholly. Governments have been toppled, Empires overthrown, because of them.`}</em></p>
    <div style={{
      "textAlign": "right"
    }}>—Temren Sarigul, History of the Dawn</div>
    <p>{`Scions are chosen by `}<a parentName="p" {...{
        "href": "/Primal%20Spirit%20of%20Bone",
        "title": "Primal Spirit of Bone"
      }}>{`Primal Spirit of Bone`}</a>{`, completing an implicit `}<a parentName="p" {...{
        "href": "/The%20Exchange",
        "title": "The Exchange"
      }}>{`Exchange`}</a>{` to bind the Shifter to the Primal Bone at birth. They are given the innate ability to change their form at will.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      